import { format } from 'date-fns';

import JSONBig from './JSONBig';

export function formatDate(
  date: Date | string,
  includeSeconds = false,
  includeMillis = false,
): string {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const timeFormat = includeMillis ? 'HH:mm:ss:SS' : includeSeconds ? 'HH:mm:ss' : 'HH:mm';

  return format(dateObj, `d-MMM-yyyy ${timeFormat}`);
}

type makeGraphUrlType = {
  uid: string;
  view?: string; // view?: AppView;
  slug?: string;
  itemID?: string;
};

const appPath = '/app';

export function makeGraphURL({
  uid,
  view = 'home',
  slug = 'slug',
  itemID = undefined,
}: makeGraphUrlType) {
  const viewURL = view ? `${view}` : '';
  const itemIdURL = itemID ? '/' + itemID : '';
  return `${appPath}/${uid}/${slug.toLowerCase()}/${viewURL}${itemIdURL}`;
}

export function copyToClipboard(value: string | number | boolean): Promise<void> {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(value.toString());
  }
  return Promise.reject();
}

export function parseBigIntJSON(value: string) {
  return JSONBig({ useNativeBigInt: true, strict: true }).parse(value);
}

export function stringifyBigIntJSON(record: object) {
  return JSONBig({ useNativeBigInt: true, strict: true }).stringify(record);
}

export function isJSON(value: string) {
  try {
    parseBigIntJSON(value);
  } catch (e) {
    return false;
  }
  return true;
}
