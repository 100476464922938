import { JSONParse, Options } from './parse';
import { stringify } from './stringify';

/**
 *
 * Our original library, json-bigint https://github.com/sidorares/json-bigint has not published a new version in 2 years
 * json-big https://github.com/HerringtonDarkholme/json-big is a more recent typescript fork but missing a commit we need to support long decimals: HerringtonDarkholme/json-big@3530541
 *
 * This is just all those things Frankenstein'd together.
 */
export default function JSONBig(options?: Options) {
  return {
    parse: JSONParse(options),
    stringify,
  };
}
export const parse = JSONParse();
export const jsonStringify = stringify;

// create the default method members with no options applied for backwards compatibility
JSONBig.parse = parse;
JSONBig.stringify = stringify;
